import React, { useEffect } from 'react'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import { Provider } from 'react-redux'
import { CoreAnalytics } from '@segment/analytics-core'
import store from '../core/store'
import { transformMetricName } from '../utils/transformMetricName'
import { config } from '../config'
import { initialize } from '../segment'
import {
  AnonymizationUtilityPlugin,
  AnonymizeTrackPlugin,
} from '../segment/plugins/anonymization'
import TrackingProvider from '../core/providers/trackingProvider'
import { PurchaseLoggerProvider } from '../core/providers/purchaseProvider'

import '@bees-web/nfa-components-react/lib/themes/base.scss'
import '../utils/log'
import '/public/fonts/global.css'

if (!globalThis.segment) globalThis.segment = initialize(config)

const ABIApp = ({ Component, pageProps }: AppProps): React.JSX.Element => {
  const { locale, applicationId, pageKey, pathname, userInfos, template } =
    pageProps || {}
  const [, country] = locale.split('_')
  const { anonymizedSegmentProperties, brazeInAppConfiguration } =
    template || {}

  globalThis.brazeInAppConfiguration = brazeInAppConfiguration

  useEffect(() => {
    globalThis.analytics = globalThis.segment[country]
    ;(globalThis.analytics as unknown as CoreAnalytics).register(
      AnonymizationUtilityPlugin(country, anonymizedSegmentProperties),
      AnonymizeTrackPlugin(country)
    )
  }, [])

  return (
    <Provider store={store}>
      <TrackingProvider
        applicationId={applicationId}
        country={country}
        pageKey={pageKey}
        pathname={pathname}
        userInfos={userInfos ?? {}}
      >
        <PurchaseLoggerProvider>
        <div className="main">
          <Component {...pageProps} />
        </div>
      </PurchaseLoggerProvider>
      </TrackingProvider>
    </Provider>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  if (typeof window.newrelic === 'object') {
    window.newrelic.setCustomAttribute(
      transformMetricName(metric.name, metric.label),
      metric.value
    )
  }
}

export default ABIApp
