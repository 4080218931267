import React, { useState } from 'react'
import Cookies from 'js-cookie'
import useOneTrust from '../hooks/useOneTrust'
import useBraze from '../hooks/useBraze'
import useTracking from '../hooks/useTracking'
import { config } from '../../config'
import { UserInfos } from '../interfaces'

interface TrackingProviderProps {
  children: React.ReactNode
  applicationId: string
  country: string
  pageKey: string
  pathname: string
  userInfos: UserInfos
}

const TrackingProvider: React.FC<TrackingProviderProps> = ({
  children,
  applicationId,
  country,
  pageKey,
  pathname,
  userInfos,
}) => {
  const { blockTrackersBeforeConsent = false } =
    config?.[country]?.onetrust || {}
  const [userId, setUserId] = useState<string | null>(
    Cookies.get('ajs_user_id')
  )

  const [trackingConsented, setTrackingConsented] = useState<boolean>(false)
  const [oneTrustInitialized, setOneTrustInitialized] = useState<boolean>(
    typeof window !== 'undefined' && !!window.OneTrust
  )
  const [path] = pathname.split('?')

  useOneTrust({
    blockTrackersBeforeConsent,
    setTrackingConsented,
    setUserId,
    oneTrustInitialized,
    setOneTrustInitialized,
  })

  useBraze({
    userId,
    country,
    pathname: path,
    trackingConsented,
    oneTrustInitialized,
    userInfos,
  })

  useTracking(applicationId, country, pageKey)

  if (userId !== Cookies.get('ajs_user_id'))
    setUserId(Cookies.get('ajs_user_id'))

  return children
}

export default TrackingProvider
