import React from 'react'
import { PurchaseLoggerContext } from '@bees-web/core/shared';

export const PurchaseLoggerProvider = ({ children }) => {
  const logPurchase = ({ productId, price, currency, quantity = 1, customAttributes }) => {
    console.info('[Braze Provider] Initialized');
    if (window.braze) {
      window.braze.logPurchase(productId, price, currency, quantity, customAttributes);
    } else {
      console.warn('Braze SDK not initialized');
    }
  };

  return (
    <PurchaseLoggerContext.Provider value={{ onInitialize: () => { }, logPurchase }}>
      {children}
    </PurchaseLoggerContext.Provider>
  );
};